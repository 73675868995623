import * as Sentry from '@sentry/sveltekit';
// Note that this import is different from the one in hooks.server.ts. We need a public env for client-side code.
import { sentrySetup } from '$lib/sentry-setup';
import { env } from '$env/dynamic/public';

if (env.PUBLIC_ENV !== 'local') {
	sentrySetup(env.PUBLIC_ENV, 'frontend');
}

export const handleError = Sentry.handleErrorWithSentry();
