import * as Sentry from '@sentry/sveltekit';

export const sentrySetup = (environment: string, app: string) => {
	Sentry.init({
		dsn: 'https://899f3d0a1e4e4b849d4d060f1897e935@o4505437131571200.ingest.sentry.io/4505442566602752',
		environment: environment,
		ignoreErrors: ['Bad credentials', 'Login required', 'Requires authentication', 'Not found: '],

		// integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0
	});
	Sentry.setTag('app', app);
};
